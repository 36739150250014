<template>
  <main class="md:flex items-center gap-x-32">
    <vc-donut
      background="#FAFAFA"
      foreground="#efefef"
      :size="200"
      unit="px"
      :thickness="20"
      haslegend
      legend-placement="right"
      :sections="sections"
      :total="sectionDetail.all"
      :start-angle="0"
      auto-adjust-text-size
      :suppress-validation-warnings="false"
    >
      <div class="bg-white text-center rounded-full w-32 h-32 flex flex-col justify-center items-center shadow-[#0000001A]/10">
        <p class="text-2xl text-brand-primary font-albertExtraBold">{{ sectionDetail.all }}</p>
        <p class="text-[#ADADAD] text-xs">Referrals</p>
      </div>
    </vc-donut>
    <div class="space-y-3 grow">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-3">
          <span class="w-2.5 h-2.5 bg-[#f6f7cf] rounded-full flex flex-col justify-center items-center"></span>
          <p class="text-[#666666] text-xs">Pending</p>
        </div>
        <p class="text-sm text-brand-primary font-albertSemiBold">{{ sectionDetail.pending }}</p>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-3">
          <span class="w-2.5 h-2.5 bg-black rounded-full flex flex-col justify-center items-center"></span>
          <p class="text-[#666666] text-xs">Shortlisted</p>
        </div>
        <p class="text-sm text-brand-primary font-albertSemiBold">{{ sectionDetail.shortlisted }}</p>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-3">
          <span class="w-2.5 h-2.5 bg-[#C8E9F9] rounded-full flex flex-col justify-center items-center"></span>
          <p class="text-[#666666] text-xs">Interviewing</p>
        </div>
        <p class="text-sm text-brand-primary font-albertSemiBold">{{ sectionDetail.interviewed }}</p>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-3">
          <span class="w-2.5 h-2.5 bg-[#F7CFE2] rounded-full flex flex-col justify-center items-center"></span>
          <p class="text-[#666666] text-xs">Rejected</p>
        </div>
        <p class="text-sm text-brand-primary font-albertSemiBold">{{ sectionDetail.rejected }}</p>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-3">
          <span class="w-2.5 h-2.5 bg-[#00BD7D] rounded-full flex flex-col justify-center items-center"></span>
          <p class="text-[#666666] text-xs">Hired</p>
        </div>
        <p class="text-sm text-brand-primary font-albertSemiBold">{{ sectionDetail.rejected }}</p>
      </div>
    </div>
  </main>
</template>
<script setup>
import 'vue-css-donut-chart/dist/vcdonut.css';
import { VcDonut } from 'vue-css-donut-chart';
import { ref } from 'vue';

const props = defineProps({
  sectionDetail: { type: Array },
});
const sections = ref([
  { value: 1, color: '#efefef' },
  { value: props.sectionDetail.shortlisted, color: 'black' },
  { value: 1, color: '#efefef' },
  { value: props.sectionDetail.pending, color: '#f6f7cf' },
  { value: 1, color: '#efefef' },
  { value: props.sectionDetail.interviewed, color: '#C8E9F9' },
  { value: 1, color: '#efefef' },
  { value: props.sectionDetail.rejected, color: '#F7CFE2' },
  { value: 1, color: '#efefef' },
  { value: props.sectionDetail.hired, color: '#00BD7D' },
  { value: 1, color: '#efefef' },
]);
</script>

<style>
.cdc-container {
  display: block !important;
}
</style>
