<template>
  <Dialog v-if="isActive" v-show="isActive" :extend-class="['md:max-w-xl p-8']">
    <template #header>
      <div class="flex items-center justify-between mb-6">
        <p class="text-lg font-albertSemiBold">Withdraw to your Bank Account</p>
        <p @click="close">close</p>
      </div>
    </template>
    <div class="space-y-6">
      <input
        type="text"
        id="account_number"
        class="bg-white border border-[#DEDEDE] text-[#8F8F8F] text-sm rounded-xl focus:border-brand-primary focus:ring-0 block w-full py-4 px-5"
        placeholder="Enter account number"
        required
        v-model="details.account_no"
      />

      <select
        class="bg-white border border-[#DEDEDE] text-[#8F8F8F] text-sm rounded-xl focus:border-brand-primary focus:ring-0 block w-full py-4 px-5"
        v-model="details.bank_code"
      >
        <option value="" disabled selected>Please select Bank</option>
        <option v-for="item in bankList" :key="item.code" :value="item.code">{{ item.name }}</option>
      </select>
    </div>
    <p class="font-albertSemiBold mt-4 text-md text-brand-primary mb-6">{{ account_name }}</p>

    <div class="flex items-center gap-x-4">
      <button
        class="bg-white border border-brand-primary text-brand-primary text-sm rounded-xl focus:border-brand-primary focus:ring-0 block w-full py-4 px-5"
        @click="close"
      >
        Cancel
      </button>
      <button class="bg-brand-primary text-white text-sm rounded-xl focus:border-brand-primary focus:ring-0 block w-full py-4 px-5">Withdraw</button>
    </div>
  </Dialog>
  <Loading :is-loading="pageLoaded" className="flex justify-center" />
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Dialog from './Dialog.vue';
import Loading from '@/components/Loading.vue';
import { useToast } from 'vue-toastification';

const props = defineProps({ isActive: { type: String, default: false } });
const store = useStore();
const toast = useToast();
const emit = defineEmits(['close']);

const details = ref({ account_no: '', bank_code: '' });
const pageLoaded = ref(false);
const account_name = ref('');

const close = () => {
  emit('close');
};

const bankList = computed(() => {
  return store.getters['global/getBankDetails'];
});

watch(details.value, async (newValue, oldValue) => {
  try {
    if (newValue.account_no !== '' && newValue.bank_code !== '') {
      pageLoaded.value = true;
      const res = await store.dispatch('global/resolveAccount', details.value);
      console.log(res);
      if (res.status === true) account_name.value = res.data.account_name;
      if (res.status === false)
        toast.warning(res.message, {
          timeout: 3000,
          hideProgressBar: true,
        });
    }
  } catch (error) {
    console.log(error, 'ERRORRRRRRR');
  } finally {
    pageLoaded.value = false;
  }
});

onMounted(async () => {
  await store.dispatch('global/getBanks');
  console.log(bankList, 'wwithdraw MODAL IS HERE');
});
</script>
