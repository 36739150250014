<template>
  <section>
    <NavBody>
      <template #body>
        <p class="font-bold text-2xl text-brand-primary">See Available Jobs</p>
        <p class="font-normal text-base text-[#8C94A6]">Have a beautiful {{ greeting }}!</p>
      </template>
    </NavBody>

    <div class="mx-auto lg:max-w-[80rem] gap-6 w-full px-4 lg:px-0 bg-[#FAFAFA]">
      <div class="">
        <form class="md:flex items-center justify-between mb-6 mt-2 bg-white px-6 py-4 gap-x-40">
          <div class="grid gap-4 md:flex grow mb-6 md:mb-0" @submit.prevent="getJobs">
            <!-- <div class="relative md:w-1/4">
              <Select place-holder="Date Posted" :options="enums.job_types" v-model="filter.date_posted" />
            </div> -->
            <!-- <div class="relative md:w-1/4">
              <Select place-holder="Experience Level" :options="enums.job_types" v-model="filter.expereince" />
            </div> -->
            <div class="relative md:w-auto flex-1">
              <input
                :value="filter.search"
                @keydown="setFilterVar($event, 'search')"
                placeholder="enter search keyword"
                class="bg-brand-light/10 capitalize text-base rounded-[10px] text-brand-black block p-3 w-full appearance-none focus:outline-none border border-brand-light focus:border-brand-dark focus:border focus:ring-1"
              />
            </div>
            <div class="relative md:w-1/4">
              <Select place-holder="Job Type" :model-value="filter.type" @change="setFilterVar($event, 'type')" :options="enums.job_types" />
            </div>
            <div class="relative md:w-1/4">
              <Select
                place-holder="Work type"
                :options="enums.job_work_types"
                :model-value="filter.work_type"
                @change="setFilterVar($event, 'work_type')"
              />
            </div>
          </div>
          <Button
            data-test="filter-jobs-button"
            label="Search"
            color="white"
            size="4px"
            class="bg-white border border-[#1C1C1E1F] rounded-lg w-full block md:w-auto md:inline-block"
            type="button"
            @click="getJobs"
          />
        </form>
        <div class="grid lg:grid-cols-3 gap-4 mb-8">
          <Card data-test="" :details="allJobs" v-if="pageLoaded" />
          <Loading :is-loading="!pageLoaded" className="flex justify-center" />
        </div>

        <br />
        <br />
        <br />
        <div class="mt-16">
          <Paginate
            v-if="pageLoaded"
            :from="paginateContent?.meta?.from"
            :to="paginateContent?.meta?.to"
            :total="paginateContent?.meta?.total"
            :current="paginateContent?.meta?.current_page"
            :next="paginateContent?.links?.next"
            :prev="paginateContent?.links?.prev"
            :pages="paginateContent?.meta?.links"
            @move="doPaginate"
          />
        </div>

        <!-- <Paginate
            data-test="paginate"
            @next="
              currentPage += 1;
              getJobs();
            "
            @prev="
              currentPage > 1 ? (currentPage -= 1) : currentPage;
              getJobs();
            "
          /> -->
      </div>
    </div>
    <Loading :is-loading="!pageLoaded" className="flex justify-center" />
  </section>
</template>

<script setup>
import Search from '@/assets/icons/search.svg?inline';
import Button from '@/components/Button.vue';
import Select from '@/components/form/Select.vue';
import Input from '@/components/form/Input.vue';
import Card from '@/components/jobCard.vue';
import NavBody from '@/components/NavBody.vue';
import Paginate from '@/components/PaginateAlt.vue';
import { ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';
import Loading from '@/components/Loading.vue';

const store = useStore();
const router = useRouter();

const pageLoaded = ref(false);
const currentPage = ref(1);

const filterVars = ref([]);
const filter = ref({
  search: '',
  type: '',
  work_type: '',
});
const filterKeys = ref({
  search: '',
  type: '',
  work_type: '',
});

const paginateContent = computed(() => {
  return store.getters['global/getAllJobs'];
});

const allJobs = computed(() => {
  return store.getters['global/getJobs'];
});

const greeting = computed(() => {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return 'Morning';
  } else if (currentHour < 18) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
});

const userDetails = computed(() => store.getters['auth/userDetails']);

const doPaginate = async ({ params }) => {
  currentPage.value = Number(params?.size) - 1;
  await getJobs();
};

const setFilterVar = async (event, key, value = '') => {
  if (event.target.type != 'checkbox') {
    filterKeys.value[key] = value ? value : event.target.value;
    filter.value[key] = value ? value : event.target.value;
  } else {
    filterKeys.value[key] = event.target.checked ? event.target.value : '';
    filter.value[key] = event.target.checked && value ? value : '';
  }

  const selected = Object.values(filter.value).filter((val) => val !== '') || [];
  filterVars.value = selected;
};

const removeFilterItem = async (k) => {
  filter.value[k] = '';
  filterKeys.value[k] = '';

  const selected = Object.values(filter.value).filter((val) => val !== '') || [];
  filterVars.value = selected;
  await getJobs();
};

const getJobs = async () => {
  const params = Object.entries(filterKeys.value).reduce((a, [k, v]) => (v == '' ? a : ((a[k] = v), a)), {});
  pageLoaded.value = false;

  try {
    await store.dispatch('global/getAllJobs', { page: currentPage.value, ...params });
    pageLoaded.value = true;
  } catch (error) {
    alert(errorMessage(error), 'error');
    pageLoaded.value = true;
    if (error.response.status === 409) router.push('/register-verify');
  }
};

const enums = computed(() => {
  return store.state.global.EnumDetails;
});

/* const categories = computed(() => {
  return store.state.global.jobCategories;
}); */

onBeforeMount(async () => {
  getJobs();
  await store.dispatch('global/getEnums');
  await store.dispatch('global/getJobCategories');
  await store.dispatch('global/getJobCategories');
});
</script>
