<template>
  <section>
    <NavBody>
      <template #body>
        <p class="font-bold text-2xl text-brand-primary">Manage your Referrals</p>
        <p class="font-normal text-base text-[#8C94A6]">Manage your wallet for payment services.</p>
      </template>
    </NavBody>
    <div class="mx-auto lg:max-w-[80rem] z-20 gap-6 w-full px-4 lg:px-0 bg-[#FAFAFA]">
      <div class="scrollbar-hide">
        <!-- <div class="bg-white border border-[#F3F4F6] rounded-xl p-5 lg:px-6 lg:py-8"> -->
        <!-- summary -->
        <div class="grid gap-4 grid-cols-3 md:grid-flow-col md:justify-stretch bg-[#FEFAF1] px-4 py-6 rounded-xl my-6">
          <div class="flex flex-col items-center justify-center">
            <p class="text-base text-[#8F8F8F] mb-4">All Transactions</p>
            <p class="font-albertExtraBold text-3xl text-[#101225] mb-2">&#8358;0.00</p>
            <p class="bg-brand-primary rounded-xl px-4 py-2 text-white text-center">{{ summaryCount.all }} Referrals</p>
          </div>
          <div class="border-l-2 h-[143px] hidden lg:block justify-self-center"></div>
          <div class="flex flex-col items-center justify-center">
            <p class="text-base text-[#8F8F8F] mb-4">Current Balance</p>
            <p class="font-albertExtraBold text-3xl text-[#101225] mb-2">&#8358;0.00</p>
            <p class="bg-brand-primary rounded-xl px-4 py-2 text-white text-center bg-opacity-20">Withdraw Money</p>
          </div>
        </div>
        <!-- table -->
        <div class="relative overflow-x-auto bg-white p-6" v-if="pageLoaded">
          <div class="mb-8">
            <p class="font-albertSemiBold text-2xl mb-2">Transaction History</p>
            <p class="text-[#8C94A6] text-base">View all your Transactions here</p>
          </div>
          <table class="w-full text-sm text-left rtl:text-right text-white" v-if="transactions.length >= 1">
            <thead class="text-xs font-bold text-white uppercase bg-brand-primary">
              <tr>
                <th scope="col" class="p-4" v-for="item in tableHead" :key="item">{{ item }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b border-[#F1F2F4]" v-for="item in transactions" :key="item">
                <th scope="row" class="px-6 py-4 font-medium text-brand-primary whitespace-nowrap dark:text-white">
                  <div>
                    <p class="text-xs text-brand-primary capitalize">{{ item.referrer_id }}</p>
                  </div>
                </th>
                <td class="px-6 py-4">
                  <div>
                    <p class="text-brand-primary text-xs capitalize">{{ item.payment_purpose }}</p>
                  </div>
                </td>
                <td class="px-6 py-4">
                  <div>
                    <p class="text-xs text-brand-primary capitalize">{{ item.amount }}</p>
                  </div>
                </td>
                <td class="px-6 py-4">
                  <div>
                    <p class="text-[10px] text-brand-primary capitalize">{{ item.status }}</p>
                  </div>
                </td>
                <td class="px-6 py-4 text-brand-primary text-xs">{{ moment(item.created_at).format(' Do MMM YYYY') }}</td>
              </tr>
            </tbody>
          </table>
          <div v-else class="text-center">
            <p class="font-albertSemiBold text-2xl mb-2">No transaction history yet</p>
            <button @click.prevent="router.push('/jobs')" class="bg-brand-primary text-white rounded-xl p-3">Refer Candidate</button>
          </div>
        </div>

        <!-- <Paginate
          data-test="paginate"
          @next="
            currentPage += 1;
            getJobs();
          "
          @prev="
            currentPage > 1 ? (currentPage -= 1) : currentPage;
            getJobs();
          "
        /> -->
        <!-- </div> -->
      </div>
      <Loading :is-loading="!pageLoaded" className="flex justify-center" />
    </div>
  </section>
</template>

<script setup>
import Card from '@/components/jobCard.vue';
import NavBody from '@/components/NavBody.vue';
import Paginate from '@/components/Paginate.vue';
import { ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';
import Loading from '@/components/Loading.vue';
import * as moment from 'moment';
import Calendar from '@/assets/icons/calendarRefer.svg?inline';
import Filter from '@/assets/icons/referFilter.svg?inline';

const store = useStore();
const router = useRouter();

const pageLoaded = ref(false);
const currentPage = ref(1);
const transactions = ref([]);

const tableHead = ref(['Transaction ID', 'Purpose of Payment', 'Amount', 'Status', 'Date']);

const userDetails = computed(() => store.getters['auth/userDetails']);
const summaryCount = computed(() => store.getters['global/getReferralCount']);

onBeforeMount(async () => {
  await store.dispatch('global/referralCount');
  transactions.value = await store.dispatch('global/walletHistory');
  pageLoaded.value = true;
});
</script>
